<div *ngIf="clientDetails?.name" id="app-header-title">
  <h1 class="app-name text-color themed-text" *ngIf="!clientDetails?.hideAppName">{{clientDetails?.name}}</h1>
  <p class="app-title text-color themed-text" [ngClass]="{'app-title-padding' : clientDetails?.hideAppName}">{{clientDetails?.title}}</p>
</div>
<section class="reset-password reset-password-wrapper">
  <div class="tab-pane fade active in reset-password-content" [class.hide]="!resetPasswordScreen">
    <h1 class="reset-password-msg">Reset password</h1>
    <div class="reset-password-info">Enter a new password for your Taylor & Francis account</div>
    <form class="form-bowl" id="ResetPasswordForm" autocomplete="off">
      <div class="form-group is-empty label-floating mrClass">
        <div class="relative">
          <p id="passwordError" hidden>{{ passwordHint }}</p>
          <p id="passwordError2" hidden>{{ passwordHint }}</p>
          <label for="Password" class="control-label" id="passwordLabel">Password</label>
          <input [type]=" viewPswd == 'icon-eye-blocked' ? 'password': 'text'" class="form-control" id="inputEmail"  
            [(ngModel)]="newPwd" name="password" [attr.aria-invalid]="isPasswordError===true?'true':'false'"
            [attr.aria-describedby]="isPasswordError===true?'passwordError2':'passwordError'"
            [ngClass]="{'inputError': isPasswordError}" 
              (blur)="validatePassword()" (input)="validatePassword()" aria-labelledby="passwordLabel">
          <button type="button" class="pwd-view-icon primary-text"
            [ngClass]="{'icon-eye-blocked': viewPswd === 'icon-eye-blocked','icon-eye': viewPswd === 'icon-eye'}"
            (click)="(viewPswd =  viewPswd == 'icon-eye-blocked' ? 'icon-eye' : 'icon-eye-blocked')"
            [attr.aria-label]="viewPswd === 'icon-eye' ? 'Hide password' : 'Show password'">
          </button>
        </div>
        <div class="errorMsg"  *ngIf="isPasswordError">
           <span class="icon-error"></span><span class="error-position">{{passwordHint}}</span>
        </div>
     </div>

     <div class="form-group is-empty label-floating mrClass">
      <div class="relative">
        <label for="ConfirmPassword" class="control-label" id="conformPasswordLabel">Confirm Password</label>
        <input [type]="viewcnfPswd == 'icon-eye-blocked' ? 'password': 'text'" class="form-control" id="confirmPwd"
          [(ngModel)]="cnfPwd" name="password" [attr.aria-invalid]="isConfirmPasswordError===true?'true':'false'"
          [attr.aria-describedby]="isConfirmPasswordError===true?'passwordError2':'passwordError'"
          [ngClass]="{'inputError': isConfirmPasswordError}" (blur)="validatePassword()" (input)="validatePassword()"
          aria-labelledby="conformPasswordLabel">
        <button type="button" class="pwd-view-icon primary-text"
          [ngClass]="{'icon-eye-blocked': viewcnfPswd === 'icon-eye-blocked','icon-eye': viewcnfPswd === 'icon-eye'}"
          (click)="(viewcnfPswd =  viewcnfPswd == 'icon-eye-blocked' ? 'icon-eye' : 'icon-eye-blocked')"
          [attr.aria-label]="viewcnfPswd === 'icon-eye' ? 'Hide password' : 'Show password'">
        </button>
      </div>
      <div class="errorMsg" *ngIf="isConfirmPasswordError">
        <span class="icon-error"></span><span class="error-position">{{newPassMatchCnfPass}}</span>
      </div>
    </div>

      <div class="form-button-access text-center" id="resetPasswordButtonContainer">
        <button color="primary" (click)="resetPassword()" id="resetPasswordButton"
          class="themed-button btn btn-primary btn-block">RESET PASSWORD</button>
      </div>
    </form>
  </div>

  <div class="tab-pane fade active in" id="forgot-password" *ngIf="forgotPasswordScreen">

    <h1 class="forgot-password-msg">Reset password</h1>

    <div class="forgot-password-wrapper">

      <div class="forgot-password-info">Enter the email address that you registered with and if you have an account, we’ll send
         you instructions on how to reset your password.</div>
      <form class="form-bowl" id="forgotPasswordForm" autocomplete="off">
        <div [ngClass]="{'has-error': (isEmailError), 'is-empty': !(email)}" class="form-group label-floating">
          <label for="inputEmail" class="control-label" id="emailLabel">Email</label>
          <input type="text" class="form-control" id="inputEmail" [(ngModel)]="email" name="email"
            (input)="invalidateEmpty()" [attr.aria-describedby]="invalidEntry()?'inputErrorMsg':'readError'"
            [ngClass]="{'inputError':isEmailError}" (blur)="validateEmailBlur()" aria-labelledby="emailLabel">
          <p id="readError" hidden>{{ errorMessage }}</p>
          <span class="input-error" [ngClass]="isEmailError===true?'class1':'class2'" id="inputErrorMsg">
            <span class="icon-error"> </span> <span class="error-position">{{ errorMessage }}</span>
          </span>
        </div>
        <div class="form-button-access text-center" id="forgotPasswordButtonContainer">
          <button (click)="sendResetPasswordLink()" color="primary" id="forgotPasswordContinueButton"
            class="themed-button continue-btn btn btn-primary btn-lg btn-block">Continue</button>
        </div>
        <div class="back-button-wrapper links" aria-label="Back to login page" tabindex="0" role="link"
        (click)="goToLogin()" (keydown.enter)="goToLogin()">
        <div class="login-path"> </div>
        <span class="back-link links">Back to Login</span>
        </div>
      </form>
    </div>
  </div>


  <div class="email-sent-wrapper" *ngIf="emailSentScreen">
    <form class="tab-pane text-center fade active in">
      <div class="image-container">
        <img src="./assets/img/fill-3.png" alt="circle" aria-hidden="true">
        <img class="tick-image" src="./assets/img/fill-1.png" alt="tick" aria-hidden="true">
      </div>
      <h1 class="email-sent-msg">Email Sent</h1>
      <div class="email-sent-info">
        {{sentNotificationMsg}}
      </div>
    </form>
  </div>

  <div class="email-sent-wrapper" *ngIf="resetPasswordSuccessScreen">
    <form class="tab-pane text-center fade active in">
      <div class="image-container">
        <img src="./assets/img/fill-3.png" alt="fill image3">
        <img class="tick-image" src="./assets/img/fill-1.png" alt="fill image1">
      </div>
      <div class="email-sent-msg">Password has been reset successfully</div>
      <div class="redirect-link-container">
        <button class="themed-button btn btn-primary login-btn" (click)="goToLogin()">Login</button>
      </div>
    </form>
  </div>

  <div *ngIf="isTokenExpired" class="reset-link-expired-container">
    <div>
      <em class="icon-error red-icon "></em>
    </div>
    <h1 class="reset-password-msg">Reset unsuccessful</h1>
    <div class="popup-description">
      The link to reset your password has expired.
      <a class="reset-link links" href="javascript:;" (click)="sendResetPasswordLink(true)"> Resend link.</a>
    </div>
  </div>
  <top-notification [message]="resentNotificationMsg" [isSuccess]="true" *ngIf="resentLink"></top-notification>
</section>
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AppState } from '../../../app.service';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-login-description',
  templateUrl: './login-description.component.html',
  styleUrls: ['./login-description.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginDescriptionComponent implements OnInit {
  @Input() fieldKey: 'signupDescription' | 'description' = null;
  loginDescription: SafeHtml;

  constructor(
    private readonly appService: AppState,
    private readonly sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    const appConfig = this.appService.getClientConfig();
    if(this.fieldKey && appConfig[this.fieldKey]){
      this.loginDescription = this.sanitizer.bypassSecurityTrustHtml(appConfig[this.fieldKey]);
    }    
  }

}

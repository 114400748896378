// modules/verify-registration/verify-registration.component.ts
//
// TS logic for `verifyRegistration` component, which is displayed when user clicks on link in sign-up email.

import { Component } from '@angular/core';
import { AppState } from '../../app.service';
import { Router, ActivatedRoute } from "@angular/router";
import { AuthapiService } from '../../services/authapi/authapi.service';

@Component({
  selector: 'verify-registration',
  templateUrl: 'verify-registration.component.html',
  styleUrls: ['verify-registration.component.scss']
})
export class VerifyRegistrationComponent {

  public errorMessage = "";
  public isInvalidVerificationToken = false;
  public username = "";

  registrationVerificationToken = "";
  isVerificationCodeExpired = false;
  clientId = null;
  clientName = null;
  brand = '';

  // Variables for notification.
  notificationMsg = '';
  isSuccess = false;
  resentEmail = false;
  state = '';
  constructor(
    private appState: AppState,
    private _route: Router,
    private activatedRoute: ActivatedRoute,
    private authapiService: AuthapiService
  ) { }

  ngOnInit() {
    const clientConfig = this.appState.getClientConfig();
    if(clientConfig){
      this.clientName = clientConfig.name;
    }
    this.activatedRoute.queryParams
      .subscribe((param: any) => {
        this.registrationVerificationToken = param["verificationToken"];
        this.clientId = param["client_id"];
        this.state = param["state"];
        this.brand = param["brand"];

        this.username = param["user"];
        this.verifyRegistrationToken();
      });
  }

  // Verify the registration token by calling the server.
  // Then proceed to re-login the user.
  verifyRegistrationToken() {
    this.isInvalidVerificationToken = false;
    this.authapiService.verifyRegistration(this.username, this.registrationVerificationToken)
      .subscribe(() => {
        (async () => {
          this.appState.set('isUserValidated', true);
          await this.relogin();
        })();
      }, err => {
        (async () => {
          await this.setErrorStateVariables(err.metadata);
        })();
      });
  }

  async relogin() {
    this.appState.set('user', {});
    let redirectUrl = "/login?verified=true";
    if (this.clientId) {
      redirectUrl += "&authorize=true" + "&client_id=" + this.clientId + "&state=" + this.state;
    }
    if (this.brand) {
      redirectUrl += "&brand=" + this.brand;
    }
    await this._route.navigateByUrl(redirectUrl);
  }

  // This method is invoked when user clicks on resend email link.
  public resendEmail() {
    this.resentEmail = false;
    this.authapiService.resendverifyLink(this.username, this.clientId, this.brand, this.clientName, this.state)
      .subscribe(() => {
        this.resentEmail = true;
        this.notificationMsg = "Verification link has been resent to " + this.username + ".";
      }, err => {
        (async () => {
          await this.setErrorStateVariables(err.metadata);
        })();
      });
  }

  public async setErrorStateVariables(errorMetadata: any) {
    if (errorMetadata) {
      this.errorMessage = errorMetadata.message.value;
      switch (errorMetadata.message.key) {
        case "VERIFICATION_LINK_EXPIRED":
          this.isVerificationCodeExpired = true;
          break;
        case "VERIFICATION_CODE_INCORRECT":
          this.isVerificationCodeExpired = true;
          break;
        case "UNEXPECTED_ERROR":
          console.log("unexpected error occured", this.errorMessage);
          break;
        case "VERIFICATION_CODE_VALIDATED":

          this.appState.set('user', {});
          let redirectUrl = "/login?alreadyVerified=true";
          if (this.clientId) {
            redirectUrl += "&authorize=true" + "&client_id=" + this.clientId + "&state=" + this.state;
          }
          if (this.brand) {
            redirectUrl += "&brand=" + this.brand;
          }
          await this._route.navigateByUrl(redirectUrl);
          break;

        case "VERIFICATION_CODE":
          this.isInvalidVerificationToken = true;
          break;
        default:
          console.log("unknown error occurred", this.errorMessage);
          break;
      }
    }
  }

}

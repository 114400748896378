// services/auth-api/auth-api.service.ts
//
// Provide services for "auth" API.
//
// Currently many services for the auth API are contained next to individual components which use them.
// This is a poor design, and should be fixed over time, by moving those services here.
//
// Note that for **new** APIs, we are preferring the use of the `accounts` endpoint.

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UrlConfig } from '../../../url-config';

@Injectable({ providedIn: 'root' })
export class AuthapiService {
  private baseURL: any = new UrlConfig().getBaseApiUrl();

  private resendVerificationURL = this.baseURL + "user/auth/resendVerifyRegistrationMail";
  private logoutURL = this.baseURL + "user/auth/mylogout";
  private updatePasswordURL = `${this.baseURL}user/auth/credentials`;
  private mfaURL = `${this.baseURL}user/auth/mfa`;
  private verifySignupURL = `${this.baseURL}user/auth/verifySignup`;
  private orgListURL = this.baseURL + "org";

  constructor(private http: HttpClient) {
  }

  // Calling api to rsend verification link for sign up.
  public resendverifyLink(userName: string, clientId: string, brand: string, clientName: string, state: string) {
    const headers = new HttpHeaders({ username: userName, clientid: clientId, brand: brand, clientName: clientName, state: state });
    return this.http.get(this.resendVerificationURL, { headers });
  }

  //Request to send email with mfa code
  public sendMFACode(userId: string) {
    let headers = new HttpHeaders({ 'userId': userId });
    let options = { headers: headers };
    return this.http.get(this.mfaURL, options);
  }

  //Request to get generated backup codes
  public generateBackUpCodes(userId: string) {
    let headers = new HttpHeaders({ 'userId': userId });
    let options = { headers: headers };
    return this.http.get(this.mfaURL + "/backupcodes", options);
  }

  // Calling api to log out user.
  public userLogout(): Observable<any> {
    return this.http.get(this.logoutURL);
  }

  // Calling api to verify MFA code
  verifyMFACode(userId: string, mfaEmailCode: string, whiteListDevice: boolean): Observable<any> {
    return this.http.post(this.mfaURL,
      {
        userId: userId,
        mfaEmailCode: mfaEmailCode,
        whiteListDevice: whiteListDevice
      });
  }


  updatePassword(username: string, oldPassword: string, newPassword: string): Observable<any> {
    return this.http.put(this.updatePasswordURL,
      {
        username: username,
        oldPassword: oldPassword,
        newPassword: newPassword
      });
  }

  // Ask the server to verify the verification token in the link in the email sent to the new user.
  verifyRegistration(username: string, registrationVerificationToken: string): Observable<any> {
    return this.http.post(this.verifySignupURL, { username, registrationVerificationToken });
  }

  getOrgList(clientId: string, entityId?: string, isActive?: string, responseGroup = 'medium'): Observable<any> {
    let orgListURL = this.orgListURL;
    if(clientId){
      orgListURL += `?client_id=${clientId}`;
    }
    if(entityId){
      orgListURL += `${orgListURL.includes('?')? '&' : '?'}entityId=${entityId}`;
    }
    if(isActive){
      orgListURL += `${orgListURL.includes('?')? '&' : '?'}isActive=${isActive}`
    }
    orgListURL += `${orgListURL.includes('?')? '&' : '?'}responseGroup=${responseGroup}`;
    return this.http.get(orgListURL);
  }
}

import { Injectable, ViewContainerRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { UrlConfig } from '../url-config';

import { Router } from '@angular/router';
import { IClientEfsData } from './models/client.interface';

export type InternalStateType = {
  [key: string]: any
};

@Injectable()
export class AppState {

  private baseURL: any = new UrlConfig().getBaseApiUrl();
  private selfURL = this.baseURL + "user/self";
  private logOutURL = this.baseURL + "user/auth/logout";
  private officeCSDusersDomains = this.baseURL + "user/auth/office/csd/domains";

  _state: InternalStateType = {};

  user$ = new BehaviorSubject<any>({});
  roles$ = new BehaviorSubject<any>({});
  domains$ = new BehaviorSubject<any>({});
  headerCompRef: ViewContainerRef;

  constructor(private http: HttpClient, private _route: Router) { }

  // already return a clone of the current state
  get state() {
    this._state = this._clone(this._state);
    return this._state;
  }

  // never allow mutation
  set state(value) {
    throw new Error('do not mutate the `.state` directly');
  }

  get(prop?: any) {
    // use our state getter for the clone
    const state = this.state;
    return state.hasOwnProperty(prop) ? state[prop] : {};
  }

  set(prop: string, value: any) {
    // internally mutate our state
    this._state[prop] = value;
    return this._state[prop];
  }

  loggedInUser(): Observable<any> {
    return this.http.get(this.selfURL);
  }

  public getTokenAndlogoutUser(sendClientId = true): Promise<void> {
    return new Promise((resolve, reject) => {
      let idToken: string;
      const userObj = this.user$.getValue();
      const logoutUserFn = (user: any) => {
        if (user.accessToken?.length && user.accessToken[0].idToken) {
          idToken = user.accessToken[0].idToken;
        } else if (user.accessToken?.length && user.accessToken[1].idToken) {
          idToken = user.accessToken[1].idToken;
        }
        this.logoutUser(idToken, sendClientId).subscribe(res => {
          this.user$.next({});
          this.set('user', {});
          // Clear roles from app state
          this.roles$.next(null);
          if (res?.body?.redirect_uri) this.redirectToUrl(res.body.redirect_uri);
          resolve();
        }, err => {
          reject(err);
        });
      };
      if (userObj?.accessToken?.length) {
        logoutUserFn(userObj);
      } else {
        this.loggedInUser().subscribe(
          user => {
            logoutUserFn(user);
          },
          err => {
            reject(err);
          }
        );
      }
    });
  }
  

  redirectToUrl(url:string){
    window.location.replace(url);
  }

  logoutUser(idToken: string, sendClientId = true): Observable<any> {
    const clientId = sessionStorage.getItem("clientId");
    if (clientId && sendClientId) {
      return this.http.post(this.logOutURL, { "idtoken": idToken, "client_id": clientId }, { observe: 'response' });
    }
    return this.http.post(this.logOutURL,{"idtoken":idToken});
  }

  getInformaEmailDomains(): Observable<any> {
    return this.http.get(this.officeCSDusersDomains);
  }

  private _clone(object: InternalStateType) {
    // simple object clone
    return JSON.parse(JSON.stringify(object));
  }

  async loadUserProfileComponent(isLoggedIn: boolean) {
    this.headerCompRef?.clear();
    if (isLoggedIn) {
      const userProfileComponent = (await import('../app/modules/account/user-pofile/user-profile.component')).UserProfileComponent;
      const compInstance = this.headerCompRef.createComponent(userProfileComponent).instance;
      compInstance.profileActions.subscribe((actions: any) => {
        (async () => {
          if (actions?.actionType == 'logout') {
            await this.getTokenAndlogoutUser();
            this.headerCompRef?.clear();
            await this._route.navigate(['/login']);
          }
          if (actions?.actionType == 'youraccount') {
            await this._route.navigate(['accounts/your-account']);
          }
        })();
      });
      this.user$.subscribe(user => {
        compInstance.userData = user;
      })
    }
  }

  public getClientConfig(): IClientEfsData {
    /**
     * If present in localStorage return client config after validation or else send null
     */
    let clienConfig = localStorage.getItem('clientConfig');
    if (clienConfig) {
      return JSON.parse(clienConfig);
    } else {
      return null;
    }

  }
}

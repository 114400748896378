<top-notification [message]="notificationMsg" [isSuccess]="isSuccess" *ngIf="showTopNotification"></top-notification>

<section class="login-section" *ngIf="localState.loginSection ">
  <app-loading *ngIf="loading"></app-loading>

  <div id="login-header-title">
    <!-- text-color will be controlled by theme.-->
    <h1 class='app-name text-color themed-text' *ngIf="!localState.clientConfig">Login to your Taylor & Francis account </h1>
    <h1 class='app-name text-color themed-text' *ngIf="localState.clientConfig?.name && !localState.hideAppName">{{ localState.clientConfig.name }}</h1>
    <p class="app-title" [ngClass]="[localState.hideAppName ? 'app-title-padding' : '']" *ngIf="localState.clientConfig?.title">{{ localState.clientConfig.title }}</p> 
  </div>

  <div class="text-center login-title-sub-flow-container"
    *ngIf="postLoginFailureKey || (localState.clientConfig?.subFlowText && localState.clientConfig.subFlowJournal)">
    <div *ngIf="localState.clientConfig?.subFlowText && localState.clientConfig.subFlowJournal">
      {{ localState.clientConfig.subFlowText }}
      <button id="journalLink" type="reset" class="links forgot-pwd" (click)="onClickRedirectToHomelink()">{{ localState.clientConfig.subFlowJournal }}</button>
    </div>
    <div class="error-text-container" *ngIf="postLoginFailureKey" 
    [ngClass]="{
      'mt-15': localState.clientConfig?.subFlowText && localState.clientConfig.subFlowJournal,
      'align-left': postLoginFailureKey === 'SAML_IDENTIFIER_EMPTY'
    }">
      <ng-container *ngIf="postLoginFailureKey === 'MALICIOUS_ACCOUNT'">
        <span>Due to unusual activity, your account has been suspended. Please contact our 
          <a href="mailto:journalshelpdesk@taylorandfrancis.com?subject=Taylor%20%26%20Francis%20account%20suspension" class="links forgot-pwd">support</a> team.</span>
      </ng-container>
      <ng-container *ngIf="postLoginFailureKey === 'SAML_IDENTIFIER_EMPTY'">
        <span>The response did not contain a valid SAML assertion. Missing ‘eduPersonTargetedID’ / ‘pairwise-id’ attribute in SAML response. Please try again. If the problem persists please contact your institution’s administrator for assistance. </span>
      </ng-container>
    </div>
  </div>

  <div class="login-wrapper" [ngClass]="[!localState.clientConfig ? 'login-wrapper-no-config' : '']">
    <input id="toggle" type="checkbox" class="hideToggle" *ngIf="!localState.loginSection">
    <div class="login-inner-wrapper">
      <div id="with-email" class="tab-content" [ngClass]="{'login-without-description': !localState.clientConfig?.description}">
        <h2 class='with-email-label' for="toggle" *ngIf="!localState.clientConfig">With email</h2>
        <div class="tab-pane text-center fade active in login-form-title" *ngIf="localState.clientConfig && localState.loginModalTitle">
          <ng-container>Login to continue</ng-container>
        </div>
        <div class="tab-pane text-center fade active in">
          <span class="error-msg" *ngIf="localState.isUnexpectedError">{{ localState.errorMessage }}</span>
          <div class="flex-container">  
            <ng-container *ngFor="let method of localState.loginMethod; let index=index">
              <div class="form-bowl institution-login-container" *ngIf="method.value.toLowerCase() === 'institution'" [class]="method.width">
                <button class="btn btn-block btn-institution" id="shibbolethLogin" (keydown.enter)="orgList()" (click)="orgList()">
                  <div class="align-center justify-center">
                    <img class="login-btn-image" src="assets/img/institution-icon.png" alt="Login with Institution">
                    <span>Login with Institution</span>
                  </div>
                </button>
              </div>

              <div class="form-bowl orcid-login-container" *ngIf="method.value.toLowerCase() === 'orcid'" [class]="method.width">
                <button class="btn btn-orcid btn-block" id="orcidBtn" #orcidButton
                  (keydown.enter)="orcidLogin()" (click)="orcidLogin()">
                  <div class="align-center justify-center">
                    <img class="login-btn-image" src="assets/img/orcid.png" alt="Login with orcid">
                    <span>Login with ORCID</span>
                  </div>
                </button>
              </div>
              
              <form class="form-bowl" (ngSubmit)="login()" [class]="method.width" *ngIf="method.value.toLowerCase() === 'usernamepassword'" autocomplete="off">
                
                <div class="orcid-divider-section" *ngIf="localState.loginMethod.length > 1 && index === localState.loginMethod.length - 1">
                  <div class="divider"></div>
                  <span class="divider-text">&nbsp;OR&nbsp;</span>
                  <div class="divider"></div>
                </div>

                <div [ngClass]="{'has-error': (localState.isInvalidEmail),'is-empty': !(localState.email)}"
                  class="form-group is-empty label-floating remove-margin-top remove-padding-bottom">
                  <label for="inputEmail" class="control-label" [class.active]="localState.email">Email</label>
                  <input type="text" class="form-control" [(ngModel)]="localState.email" name="inputEmail"
                    (input)="validateEmail()" (blur)="validateEmailBlur()"
                    [attr.aria-describedby]="localState.isInvalidEmail ?'invalidMailError':'invalidMailValue'"
                    (ngModelChange)="redirectToOffice365IfInformaDomain(localState.email)" id="inputEmail"
                    [ngClass]="{'inputError':localState.isInvalidEmail}">
                  <p id="invalidMailValue" hidden>{{ localState.errorMessage }}</p>
                  <span class="input-error" [ngClass]="localState.isInvalidEmail===true?'class1':'class2'"
                    id="invalidMailError">
                    <span class="icon-error"> </span> <span class="error-position">{{ localState.errorMessage }}</span>
                  </span>
                </div>
                <div [ngClass]="{'has-error': (localState.isIncorrectPassword),'is-empty': !(localState.password)}"
                  class="form-group is-empty label-floating password remove-padding-bottom">
                  <label for="inputPassword" class="control-label" [class.active]="localState.password">Password</label>
                  <input [disabled]="isOffice365Login" [type]=" viewPswd == 'icon-eye-blocked' ? 'password': 'text'"
                    class="form-control"
                    [(ngModel)]="localState.password" name="password" (blur)="validatePasswordBlur()"
                    (input)="validatePassword()" id="inputPassword"
                    aria-describedby="invalidPassValue"
                    [ngClass]="{'inputError': isIncorrectPassword}"
                    [ngClass]="{'inputError':localState.isInvalidPassword || localState.isIncorrectPassword || localState.isIncorrectEmail || blurPasswordError}" />
                    <button type="button" class="pwd-view-icon primary-text" [ngClass]="{ 
                      'icon-eye-blocked': viewPswd === 'icon-eye-blocked',
                      'icon-eye': viewPswd === 'icon-eye'
                    }" (click)="(viewPswd =  viewPswd == 'icon-eye-blocked' ? 'icon-eye' : 'icon-eye-blocked')"
                    [attr.aria-label]="viewPswd === 'icon-eye' ? 'Hide password' : 'Show password'">
                    </button>
                  <p id="invalidPassValue" [innerHTML]="localState.errorMessagePassword" hidden></p>
                  <span class="input-error pwd-error"
                    *ngIf="localState.isInvalidPassword || localState.isIncorrectPassword || localState.isIncorrectEmail || blurPasswordError || localState.isUserBlocked">
                    <span class="icon-error"
                      [ngClass]="{'credentialErrorIcon':localState.isInvalidPassword || localState.isIncorrectEmail|| localState.isUserBlocked}">
                    </span>
                    <span id="passwordError" [innerHTML]="localState.errorMessagePassword" class="error-position"></span>
                  </span>
                </div>
                <div class="form-group users-help-box">
                  <a class="pull-right links forgot-pwd" href="javascript:;" (click)="forgotPassword(localState)">Forgot
                    password?</a>
                </div>
                <div class="form-button">
                  <button color="primary" class="themed-button btn btn-primary btn-block login-btn" id="loginBtn"
                    #loginButton>{{isOffice365Login? 'Login with Informa SSO': 'Login'}}</button>
                </div>
              </form>
            </ng-container>
          </div>
        </div>
        <div id="signupWrapper" class="text-color themed-text" *ngIf="localState.createTnfAccount">
          <span> Don’t have an account yet? 
            <a class="sign-up links text-color themed-text" href="javascript:;"
              aria-label="Register" (click)="navigateToSignUp()">Register</a>
          </span>
        </div>

        <div class="social-networks">
          <div class="login-info">or connect with</div>
          <div class="network-detail">
            <form ngNoForm action="{{localState.facebook}}" method="get" class="ng-pristine ng-valid">
              <input id="facebook" aria-label="Facebook" type="submit" />
              <input type="hidden" name="clientId" [value]="localState.clientId" />
            </form>
          </div>
          <div class="network-detail">
            <form ngNoForm action="{{localState.linkedin}}" method="get" class="ng-pristine ng-valid">
              <input id="linkedin" aria-label="Linkedin" type="submit" />
              <input type="hidden" name="clientId" [value]="localState.clientId" />
            </form>
          </div>
          <div class="network-detail">
            <form ngNoForm action="{{localState.google}}" method="get" class="ng-pristine ng-valid">
              <input id="google" aria-label="Google" type="submit" />
              <input type="hidden" name="clientId" [value]="localState.clientId" />
            </form>
          </div>
        </div>
      </div>

      <div class="client-description tab-content" *ngIf="localState.clientConfig?.description" 
        [ngClass]="{'pt-30': !localState.loginModalTitle, 'pt-40': localState.loginModalTitle}">
        <app-login-description fieldKey="description"></app-login-description>
      </div>
      <div class="inst-login" *ngIf="!localState.clientConfig">
        <!-- show Login option based on Configuration below -->
        <div class="shib-login align-elements" id="shibbolethLogin" tabindex="0" (keydown.enter)="orgList()"
          (click)="orgList()">
          <div>
            <h2 class="inst-msgs">
              With Shibboleth or OpenAthens
            </h2>
            <div class="research-msg">
              to access research made available through your institution
            </div>

          </div>

          <div class="themed-circle login-right-arrow">
            <em class="material-icons themed-arrow right-arrow-icon-img">
              chevron_right
            </em>
          </div>

        </div>
        <div class="divider"></div>
        <!-- show Login option based on Configuration below -->
        <div class="orcid-login align-elements" tabindex="0" (keydown.enter)="orcidLogin()" (click)="orcidLogin()">
          <div>
            <h2 class="inst-msgs orcid">
              <span>With</span><img src="assets/img/new-orcid.png" alt="orcid">
            </h2>

            <div class="research-msg">
              to submit your research for publication and access your dashboard
            </div>
          </div>

          <div class="themed-circle login-right-arrow">
            <em class="material-icons themed-arrow right-arrow-icon-img">
              chevron_right
            </em>
          </div>

        </div>
      </div>
    </div>
  </div>


</section>
<section class="shib-section" *ngIf="localState.shibSection">
  <div class="institution-login-wrapper" *ngIf="localState.orgs && localState.orgs.length > 0">
    <div class="institution-msg login-form-title">
      Institution login
    </div>
    <div class="shib-oathens-login">
      To access Taylor & Francis content using your institutional entitlements, search for your institution name, then
      select it from the list.
    </div>
    <div class="search-institute-wrapper">
      <div aria-expanded="false" autocomplete="off">
        <label for="searchInstitute" hidden>Institution login</label>
        <ng-autocomplete #ngAutoComplete placeholder="Search for your institution" [data]="localState.orgs"
          historyIdentifier="recentlySelectedOrgs" [searchKeyword]="'value'" (selected)='onSelectInstitute($event)'
          (inputCleared)='inputClearedFun($event)' (inputChanged)='onChangeFun($event)'
          [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
        </ng-autocomplete>

        <ng-template #itemTemplate let-item>
          <a class="institution-option" [innerHTML]="item.value" [attr.aria-label]="item.value"></a>
        </ng-template>

        <ng-template #notFoundTemplate let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>
      </div>
    </div>

    <form class="form-bowl" (ngSubmit)="samlLogin()">
      <div class="form-button continue-btn">
        <button type="submit" color="primary" class="themed-button btn btn-primary" id="cont-btn" [disabled]="disableContinueButton">Continue</button>
      </div>
    </form>
    <a (click)="showLogin()" (keydown.enter)="showLogin()" class="backtoLogin" tabindex="0" id="backLogin" >
      <span class="backLink">Use a different login method</span>
    </a>
    <p aria-labelledby="backLogin" hidden>Click here to go to Login Page</p>
  </div>
</section>
<top-banner [message]="updateInfoMessage" [isSuccess]="false" *ngIf="showUdateInfoMessage"></top-banner>

<ng-template #usernamePasswordLogin let-item>
  <div [innerHTML]="item.value"></div>
</ng-template>
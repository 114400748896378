<top-notification [message]="notificationMsg" [isSuccess]="true" *ngIf="resentEmail"></top-notification>

<section class="signUpCommonClass" aria-label="Sign up">
   <div *ngIf="!emailSentScreen" id="app-header-title">
      <h1 class="app-name text-color themed-text" *ngIf="!clientDetails?.hideAppName">{{clientDetails?.name}}</h1>
      <p class="app-title text-color themed-text" [ngClass]="{'app-title-padding' : clientDetails?.hideAppName}" >{{clientDetails?.title}}</p>
   </div>
   <div class="signup-section" *ngIf="!emailSentScreen">
      <div class="signup-sub-section">
         <div class="sign-up-container" [ngClass]="{'signup-without-description': !viewDescription}">
            <div class="sign-up-info">
               Create account
            </div>
            <form class="form-bowl" autocomplete="off">
               <div class="form-group is-empty label-floating">
                  <label for="firstName" class="control-label">First Name</label>
                  <input type="text" class="form-control" id="firstName" [attr.aria-invalid]="isIncorrectFirstName"
                  [(ngModel)]="firstName" autocomplete="off" name="firstName"
                  [attr.aria-describedby]="'firstNameError'" [ngClass]="{'inputError':isIncorrectFirstName}"
                  (blur)="validateNameAndSetErrorsIfAny('firstName')" [maxlength]="firstNameMaxLimit">

                  <div class="errorMsg" [ngClass]="isIncorrectFirstName===true?'class1':'class2'">
                     <span class="icon-error "> </span><span class="error-position" id="firstNameError">{{
                        errorMsgFirstName }}</span>
                  </div>
               </div>

               <div class="form-group is-empty label-floating">
                  <label for="lastName" class="control-label">Last Name</label>
                  <input type="text" class="form-control" id="lastName" [attr.aria-invalid]="isIncorrectLastName"
                  [(ngModel)]="lastName" autocomplete="off" name="lastName" [attr.aria-describedby]="'lastNameError'"
                     [ngClass]="{'inputError':isIncorrectLastName}" (blur)="validateNameAndSetErrorsIfAny('lastName')"
                     [maxlength]="lastNameMaxLimit">

                  <div class="errorMsg" [ngClass]="isIncorrectLastName===true?'class1':'class2'">
                     <span class="icon-error "> </span><span class="error-position" id="lastNameError">{{
                        errorMsgLastName }}</span>
                  </div>
               </div>


               <div class='form-group is-empty label-floating'>
                  <label for="inputEmail" class="control-label">Email</label>
                  <div class="input-with-loader">
                     <input type="text" class="form-control" id="inputEmail"
                            [attr.aria-invalid]="invalidEmail === true ? 'true' : 'false'"
                            [attr.aria-describedby]="'mailError'"
                            [(ngModel)]="email" name="email"
                            [ngClass]="{'inputError': isErrorEmail || isIncorrectEmail}"
                            (blur)="validateEmailBlur()" (ngModelChange)="redirectToOffice365IfInformaDomain(email)"
                            (input)="validateEmail()">
                     <div class="loader" *ngIf="loading" [ngStyle]="{'display': loading ? 'inline-block' : 'none'}"></div>
                 </div>
                  <div class="errorMsg" [ngClass]="(isIncorrectEmail ||  isErrorEmail)===true?'class1':'class2'">
                     <span class="icon-error "> </span><span class="error-position" id="mailError" aria-live="assertive">{{ errorMsgEmail
                        }}</span>
                  </div>
               </div>

               <div class="form-group is-empty label-floating">
                  <label for="country" class="control-label">Country</label>
                  <p id="countryError" aria-live="polite" class="visually-hidden">{{ errorMsgCountry }}</p>
                  <select class="form-control" id="country" aria-labelledby="countryError"
                     [ngClass]="{'inputError':isIncorrectCountry}" (input)="validateCountry()"
                     (blur)="validateCountryBlur()" [(ngModel)]="country" name="country">
                     <option selected value=""></option>
                     <option *ngFor="let country of allCountries" [ngValue]="country.name">{{ country.name }}</option>
                  </select>
                  <div class="errorMsg" [ngClass]="isIncorrectCountry===true?'class1':'class2'">
                     <span class="icon-error "> </span><span class="error-position">{{ errorMsgCountry }}</span>
                  </div>
               </div>

               <div class="form-group is-empty label-floating">
                  <label for="Password" class="control-label" id="passwordLabel">Password</label>
                  <input [type]="viewPswd == 'icon-eye-blocked' ? 'password': 'text'" class="form-control"
                     id="inputPassword" [(ngModel)]="password" name="password"
                     [attr.aria-invalid]="isPwdHintError===true?'true':'false'"
                     [ngClass]="{'inputError': isIncorrectPassword}" [attr.aria-describedby]="'passwordError'"
                     (blur)="validatePasswordBlur()" (input)="validatePassword()" aria-labelledby="passwordLabel">
                  <button class="pwd-view-icon primary-text" [ngClass]="{
                        't-14': isIncorrectPassword, 
                        'icon-eye-blocked': viewPswd === 'icon-eye-blocked',
                        'icon-eye': viewPswd === 'icon-eye'
                     }" (click)="(viewPswd =  viewPswd == 'icon-eye-blocked' ? 'icon-eye' : 'icon-eye-blocked')"
                     [attr.aria-label]="viewPswd === 'icon-eye' ? 'Hide password' : 'Show password'">
                  </button>
                  <div class="errorMsg" [ngClass]="isIncorrectPassword?'class1':'class2'">
                     <span class="icon-error"></span><span id="passwordError" class="error-position">{{passwordHint}}</span>
                  </div>
               </div>

               <div class="form-group optin-text">
                  <p id="readPara" class="optin-text"> Taylor & Francis Group use the details you share here to create
                     an account for you.</p>
                  <p class="optin-text"> Taylor & Francis Group would also like to use your email address
                     to send you offers and information about related products and services that we think will be of
                     interest to you.
                     You may opt out of receiving these messages at any time by clicking the unsubscribe link found in
                     the footer of our emails.</p>
                  <p class="optin-text">You can find more information in our
                     <a class="links" target="_blank" aria-describedby="readPara privacy_policy"
                        [href]="privacyPolicyLink">Privacy Policy</a>
                  </p>
                  <p id="privacy_policy" hidden>Click to find more information about privacy policy.</p>
               </div>

               <div class="form-group terms-check-box">
                  <span class="checkbox pull-left no-margin">
                     <input type="checkbox" id="signup-chk1" class="custom-checkbox  custom-control-input"
                        [(ngModel)]="optOut" name="optOut" />
                     <label class="custom-control-label" for="signup-chk1">If you do not want to receive marketing
                        communication from us, please tick the box</label>
                  </span>
               </div>

               <div class="form-group terms-check-box">
                  <span class="checkbox pull-left no-margin">

                     <input type="checkbox" id="termsCheckBox" class="custom-checkbox  custom-control-input"
                        (ngModelChange)="resetCheckboxFlag()" [(ngModel)]="isChecked" aria-describedby="t&cError"
                        name="isChecked" />
                     <label class="custom-control-label" id="termsAndCondition" for="termsCheckBox"> I agree to the <a
                           class="links" target="_blank" [href]="termsOfUseLink"
                           aria-describedby="terms_conditions">Terms & Conditions</a></label>

                     <p id="terms_conditions" hidden>Click to know more about terms and conditions.</p>
                     <p id="t&cError" hidden>{{ errorMsgTerms }}</p>
                  </span>
               </div>

               <div class="errorCheckBox" [ngClass]="isCheckBoxNotClicked===true?'class1':'class2'">{{ errorMsgTerms }}
               </div>
               <div id='hcaptcha' class="form-button-access h-captcha" [attr.data-sitekey]="siteKey"
                  data-callback="onSubmit">
               </div>
               <div *ngIf="!isCaptchaValid" class="h-captcha-err" aria-live="polite">{{hCaptchaErrMsg}}</div>
               <div class="form-group form-button-access text-center">
                  <button (click)="validate()" [disabled]="isSubmitted" [ngClass]="{ 'btnDisabled': isSubmitted}"
                     id="signUpButton" #signupBtn class="themed-button btn btn-primary btn-block btn-lg signUpBtn "
                     aria-label="Create Account">
                     Create Account
                  </button>
                  <app-loading *ngIf="validateFormLoading"></app-loading>
               </div>

            <div id="loginLink" class="text-color themed-text">
                  <p>Already have an account? <a class="sign-up text-color themed-text links" href="javascript:;" (click)="navigateToLogin()"
                        aria-label=" Already have an account? Login now">Login now</a>
                  </p>
               </div>

            </form>
         </div>
         <div class="description-container sign-up-container pt-40" *ngIf="viewDescription">
            <app-login-description fieldKey="signupDescription"></app-login-description>
         </div>
      </div>
   </div>

   <div *ngIf="emailSentScreen" class="emailSentScreen" role="region" aria-label="Email verification">
      <p class="emailSent">Thank you for registering with us</p>
      <div class="emailSent-info">
         <div>We have sent you a verification message to your registered email address <span class="mailId">
               {{email}}</span>. Please check your email.</div>
      </div>
      <a class="redirectLink" href="javascript:;" (click)="resendMail()">Didn't get the email? Resend</a>.
   </div>

</section>
<app-loading *ngIf="loading"></app-loading>
<div class="orcid-email-wrapper">
  <div class="tab-pane fade active in" id="orcidEmail">
    <h1 class="popup-title">{{ title }}</h1>
    <div *ngIf="showUserDetails">
      <p>
        It is important that the details we have about you is up-to-date so that we can keep you informed of important
        service changes or security advisories.<b *ngIf="isVerifyDetailsPage">Updates to your account will be applied within 24 hours.</b>
      </p>
      <p>
        Please take a moment to review the details we hold about you.
      </p>
    </div>

    <div class="text-center login-title-sub-flow-container" *ngIf="postLoginFailureKey === 'MALICIOUS_ACCOUNT'">
      <div class="error-text-container">
        <ng-container>
          <span>Due to unusual activity, your account has been suspended. Please contact our 
            <a href="mailto:journalshelpdesk@taylorandfrancis.com?subject=Taylor%20%26%20Francis%20account%20suspension" class="links forgot-pwd">support</a> team.</span> 
        </ng-container>
      </div>
    </div>
    <form #signUpForm="ngForm" class="form-bowl" autocomplete="off" (ngSubmit)="saveOrcidEmail()" novalidate>
      <div *ngIf="showUserDetails" class="form-group is-empty label-floating mrClass">
        <label [class.active]="firstName && firstName.length > 0" for="firstName" class="control-label">First Name</label>
        <input type="text" class="form-control" id="firstName" [attr.aria-invalid]="isIncorrectFirstName"
          [(ngModel)]="firstName" autocomplete="off" name="firstName" [attr.aria-describedby]="'firstNameError'"
          [ngClass]="{'inputError':isIncorrectFirstName}" (blur)="validateNameAndSetErrorsIfAny('firstName')"
          [maxlength]="firstNameMaxLimit" placeholder=" " >

        <div class="errorMsg" [ngClass]="isIncorrectFirstName===true?'class1':'class2'">
          <span class="icon-error "> </span><span class="error-position" id="firstNameError">{{
            errorMsgFirstName }}</span>
        </div>
      </div>

      <div *ngIf="showUserDetails" class="form-group is-empty label-floating mrClass">
        <label [class.active]="lastName && lastName.length > 0" for="lastName" class="control-label">Last Name</label>
        <input type="text" class="form-control" id="lastName" [attr.aria-invalid]="isIncorrectLastName"
          [(ngModel)]="lastName" autocomplete="off" name="lastName" [attr.aria-describedby]="'lastNameError'"
          [ngClass]="{'inputError':isIncorrectLastName}" (blur)="validateNameAndSetErrorsIfAny('lastName')"
          [maxlength]="lastNameMaxLimit" placeholder=" " >

        <div class="errorMsg" [ngClass]="isIncorrectLastName===true?'class1':'class2'">
          <span class="icon-error "> </span><span class="error-position" id="lastNameError">{{
            errorMsgLastName }}</span>
        </div>
      </div>

      <div *ngIf="showUserDetails" class='form-group is-empty label-floating'
        [ngClass]="errorLengthExceeds ? 'mrClass3' : 'mrClass'">
        <label [class.active]="email" for="inputEmail" class="control-label">Email</label>
        <input type="text" class="form-control" id="inputEmail" [attr.aria-invalid]="invalidEmail===true?'true':'false'"
          [attr.aria-describedby]="'mailError'" [(ngModel)]="email" name="email"
          [ngClass]="{'inputError': isErrorEmail || isIncorrectEmail}" (blur)="validateEmailBlur()"
          (input)="validateEmail()" [disabled]="disableEmail" placeholder=" " >

        <div class="errorMsg" [ngClass]="(isIncorrectEmail ||  isErrorEmail)===true?'class1':'class2'">
          <span class="icon-error "> </span><span class="error-position" id="mailError">{{ errorMsgEmail
            }}</span>
        </div>
      </div>

      <div *ngIf="showUserDetails" [ngClass]="{'has-error': !country.name && submitted, 'is-empty': !country.name}"
        class="form-group label-floating">
        <label [class.active]="country && country.name" for="country" class="control-label">Country/Region</label>
        <select class="form-control" (focus)="focusCountry()" (blur)="validateCountryBlur()" id="country"
          [ngClass]="{'inputError':!country.name && submitted}" (ngModelChange)="onCountryChange($event)"
          [(ngModel)]="country" name="country" required>
          <option *ngFor="let country of allCountries" [ngValue]="country">{{ country.name }}</option>
        </select>
        <span class="icon-error input-error-icon" *ngIf="!country.name && submitted"> </span>
        <span class="codeError" *ngIf="!country.name && submitted">{{ errorMsgCountry }}</span>
      </div>
      <div class="form-group optin-text">
        <p id="readPara" class="optin-text"> Taylor & Francis Group use the details you share here to create an account
          for you.</p>
        <p class="optin-text">Taylor & Francis Group would also like to use your email address to send you offers and
          information about related products and services that we think will be of interest to you.<br>
          You may opt out of receiving these messages at any time by clicking the unsubscribe link found in the footer
          of our emails.</p>
        <p class="optin-text"> You can find more information in our
          <a class="underline links" target="_blank" aria-describedby="readPara privacy_policy"
            [href]="privacyPolicyLink">Privacy Policy</a>
        </p>

        <p id="privacy_policy" hidden>Click to find more information about privacy policy.</p>
      </div>

      <div class="form-group terms-check-box">
        <span class="checkbox pull-left no-margin">

          <input type="checkbox" [checked]="optout" id="signup-chk1" class="custom-checkbox  custom-control-input"
            [(ngModel)]="optout" name="optout" />
          <label class="custom-control-label" for="signup-chk1">If you do not want to receive marketing communication
            from us, please tick the box</label>

        </span>
      </div>

      <div class="form-group terms-check-box" *ngIf="getTermsChecked()">
        <span class="checkbox pull-left no-margin">

          <input type="checkbox" id="termsCheckBox" class="custom-checkbox  custom-control-input"
            [(ngModel)]="isTermsChecked" aria-describedby="t&cError" name="isChecked" />
          <label class="custom-control-label" id="termsAndCondition" for="termsCheckBox"> I agree to the <a
              class="underline links" target="_blank" [href]="termsOfUseLink" aria-describedby="terms_conditions">Terms
              & Conditions</a></label>

          <p id="terms_conditions" hidden>Click to know more about terms and conditions.</p>
        </span>
      </div>
      <p id="t&cError" hidden>* Agreement to Terms & Conditions is
        required</p>
      <div *ngIf="!isTermsChecked && submitted" class="error-msg">* Agreement to Terms & Conditions is
        required</div>
      <div class="form-button-access text-center">
        <button color="primary" class="custom-button themed-button btn btn-primary btn-lg">Continue</button>
      </div>
    </form>
  </div>
</div>
/*
 * Orcid component
 * When user is logged in through orcid,
 * If user response from orcid comes without email, then user will be navigated to this component to provide their email.
 */
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from "@angular/router";
import { nameValidator, validateEmail, MAX_CHARACTER_LIMIT } from '@tandfgroup/form-field-validator'
import { OrcidShibbolethService } from './orcid-shibboleth.service';
import { AppState } from '../../app.service';
import { UrlConfig } from '../../../url-config';
import { sortBy } from 'lodash-es';
import { OrcidDialogBoxComponent } from '../../components/ui/orcid-dialog-box/orcid-dialog-box.component';
import { Subscription } from 'rxjs';
import { AuthapiService } from '../../services/authapi/authapi.service';
import { DOCUMENT } from '@angular/common';

const baseURL: string = new UrlConfig().getBaseApiUrl();
const TIMEOUT_INTERVAL = 2000;
const TIMEOUT_INTERVAL_EMAIL = 1000;

@Component({
  selector: 'orcid-shibboleth',
  styleUrls: ['./orcid-shibboleth.component.scss'],
  templateUrl: './orcid-shibboleth.component.html',
  providers: [OrcidShibbolethService],
  encapsulation: ViewEncapsulation.None
})
export class OrcidShibbolethComponent implements OnInit {
  public sPClientId: string = new UrlConfig().getSPClientId();

  config = new UrlConfig();
  email: string = '';
  country: any = {};
  optout: boolean = false;
  isTermsChecked: boolean = false;
  isTermsCheckedPrestine: boolean = false;
  submitted: boolean = false;
  emailCannotBeEmptyMessage: string = 'Email address required';
  errorMsgCountry: string = 'Country/Region cannot be empty';
  invalidEmailFormatMessage: string = 'Must be an email address.';
  errorMessage: string = '';
  clientId: string = '';
  authorize: boolean = false;
  authorizeAction: string = baseURL + 'user/auth/authorize';
  isIncorrectCountry: boolean = false;
  allCountries: any[] = [];
  geoLocations: any = {};
  isShibboleth: boolean = false;
  isTnfLogin: boolean = false;
  userId: string = '';
  private timer;
  clientConfig: any = {};
  termsOfUseLink: string = '';
  privacyPolicyLink: string = '';
  skipWechatLogin: boolean = false;
  countryExists: boolean = false;
  isOrcidLogin: boolean = false;
  state: string = '';
  hideOptOut = false;
  usersDataSubscription?: Subscription;
  loading: boolean = false;
  showEmailInput = false;
  isIncorrectFirstName = false;
  isIncorrectLastName = false;
  firstName = '';
  lastName = '';
  firstNameMaxLimit: number = null;
  lastNameMaxLimit: number = null;
  errorMsgFirstName: string = '';
  errorMsgLastName: string = '';
  errorLengthExceeds = false;
  invalidEmail: boolean = false;
  isErrorEmail = false;
  isIncorrectEmail = false;
  errorMsgEmail: string = '';
  disableEmail = false;
  showAddPopup = false;
  isEmailError: boolean;
  addtionalDetails: string = 'Additional information required';
  title: string = 'Verify your details'
  showUserDetails: boolean = true;
  postLoginFailureKey:string="";
  isVerifyDetailsPage: boolean = true;
  constructor(
    public orcidShibbolethService: OrcidShibbolethService,
    public appState: AppState,
    public _route: Router,
    public dialog: MatDialog,
    private authServices: AuthapiService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
    this.firstNameMaxLimit = MAX_CHARACTER_LIMIT.firstName;
    this.lastNameMaxLimit = MAX_CHARACTER_LIMIT.lastName;
    this.clientConfig = this.config.getClientConfig(this.appState.get('brand'));
    this.termsOfUseLink = this.clientConfig.termsOfUseLink;
    this.privacyPolicyLink = this.clientConfig.privacyPolicy;
    this.fetchCountries();
    console.log('this.appState.get', this.appState.get('queryParams'))
    if (Object.keys(this.appState.get('queryParams')).length > 0) {
      this.clientId = this.appState.get('queryParams')['client_id'];
      this.authorize = Boolean(this.appState.get('queryParams')['authorize']);
      this.isShibboleth = Boolean(this.appState.get('queryParams')['isShibboleth']);
      this.isOrcidLogin = Boolean(this.appState.get('queryParams')['isOrcid']);
      if((this.appState.get('queryParams')['countryExists'] || this.appState.get('queryParams')['country']) && this.appState.get('queryParams')['country'] !== 'null'){ this.country['name'] = this.appState.get('queryParams')['country']; }
      this.isTnfLogin = Boolean(this.appState.get('queryParams')['isTnfLogin']);
      this.userId = this.appState.get('queryParams')['userId'];
      this.state = this.appState.get('queryParams')['state'];
      if(this.isOrcidLogin && !this.appState.get('queryParams')['email']) {
        this.title = this.addtionalDetails;
        this.isVerifyDetailsPage = false;
      }
      if(this.appState.get('queryParams')['consent'] === 'false') {
        this.getRequiredDetails()
      }
      if(this.appState.get('queryParams')['optOut'] === 'true' || this.appState.get('queryParams')['optOut'] === true){ this.optout = true; }
      else { this.optout = false; }
      this.setAdditionalInfo();
      this.showEmailInput = this.appState.get('queryParams')['getEmailId'];
    }
    console.log('this data', this.optout, this.appState.get('queryParams'),this.country, this.firstName, this.lastName, this.email)
  }
  setAdditionalInfo() {
    this.firstName = this.appState.get('queryParams')['firstName'];
    this.lastName = this.appState.get('queryParams')['lastName'];
    this.email = this.appState.get('queryParams')?.email === 'null' || this.appState.get('queryParams')?.email === null ||
    this.appState.get('queryParams')?.email === '' ? '' : this.appState.get('queryParams')?.email;     console.log('this.email', this.email)  
    if(this.email) {
      this.disableEmail = true;
    }else {
      this.disableEmail = false;
    }
    this.showAddPopup = this.appState.get('queryParams')['showAddPopup'] === 'true' || this.appState.get('queryParams')['showAddPopup'] === true;
    this.isTermsChecked = this.appState.get('queryParams')['consent'] === 'true' ||  this.appState.get('queryParams')['consent'] === true;
    this.isTermsCheckedPrestine = this.isTermsChecked;
    console.log('this.isTermsChecked', this.isTermsChecked, 'this.isTermsCheckedPresine', this.isTermsCheckedPrestine)
    this.setCountryCode();
  }

  setCountryCode() {
    if(this.appState.get('queryParams')['countryCode'] && this.appState.get('queryParams')['countryCode'] !== 'null') {
      this.country.countryCode = this.appState.get('queryParams')['countryCode'];
    }
  }

  validateNameAndSetErrorsIfAny(fieldName: 'firstName' | 'lastName') {
    const value = fieldName === 'firstName' ? this.firstName : this.lastName;
    const fieldUiName = {
      firstName: 'First Name',
      lastName: 'Last Name',
    };
    let errorMessage = '';
    if (!value) {
      errorMessage = `${fieldUiName[fieldName]} required`;
    } else {
      const validateNameRes = nameValidator(value, fieldName);
      console.log('validateNameRes', validateNameRes)
      console.log('validateNameRes', validateNameRes, fieldName, this.firstName)
      if(!validateNameRes.valid) {
        errorMessage = validateNameRes.description || `${fieldUiName[fieldName]} invalid`;
      }
    }
    if (fieldName === 'firstName') {
      this.isIncorrectFirstName = !!errorMessage;
      this.errorMsgFirstName = errorMessage;
    } else {
      this.isIncorrectLastName = !!errorMessage;
      this.errorMsgLastName = errorMessage || '';
    }
    console.log('errorMsgFirstName', this.errorMsgFirstName)
  }

  validateEmail() {
    this.isErrorEmail = false;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      const validateEmailRes = validateEmail(this.email);
      console.log('validateEmailRes validateEmail', validateEmailRes)
      if (this.email === "" || !validateEmailRes.valid) {
        this.errorMsgEmail = this.email ? validateEmailRes.description : "Email address required";
        this.errorLengthExceeds = this.errorMsgEmail.length>=70;
        this.invalidEmail = true;
        this.isErrorEmail = true;
      }
    }, TIMEOUT_INTERVAL_EMAIL);
  }

  validateEmailBlur() {
    const validateEmailRes = validateEmail(this.email);
    console.log('validateEmailRes validateEmailBlur', validateEmailRes)
    if (this.email === "" || !validateEmailRes.valid) {
      this.errorMsgEmail = this.email ? validateEmailRes.description : "Email address required";
      this.errorLengthExceeds = this.errorMsgEmail?.length>=70;
      this.isErrorEmail = true;
    }
    console.log('isErrorEmail', this.isErrorEmail, 'errorMsgEmail', this.errorMsgEmail)
  }
  saveOrcidEmail() {
    this.validateNameAndSetErrorsIfAny('firstName');
    this.validateNameAndSetErrorsIfAny('lastName');
    this.submitted = true;
    this.isEmailError = false;
    this.isIncorrectCountry = false;
    this.email = this.email.trim();
    this.postLoginFailureKey="";
    this.emailErrorOnSaveORCIDEmail();

    if (this.country.name && this.country.name === 'China' && this.clientId && this.clientId === this.sPClientId && !this.skipWechatLogin && this.isOrcidLogin) {
      this.openDialog();
    } else if (this.isIncorrectFirstName) {
      document.getElementById("firstName").focus();
      return
    } else if (this.isIncorrectLastName) {
      document.getElementById("lastName").focus();
      return
    } else if (!this.isErrorEmail && this.firstName && this.lastName && this.email && this.country.name && this.isTermsChecked && this.isOrcidLogin) {
      this.updateORCIDUserOnSaveORCIDEmail();
    } else if (!this.countryExists && !this.country.name) {
      document.getElementById("country").focus();
    } else if (!this.isTermsChecked) {
      document.getElementById("termsCheckBox").focus();
    } else if (this.country.name && this.isTermsChecked && this.isShibboleth) {
      this.updateSibUserOnSaveORCIDEmail();
    } else if (!this.isErrorEmail && this.firstName && this.lastName && this.email && this.country.name && this.isTermsChecked && this.isTnfLogin) {
      this.updateTnFUserOnSaveORCIDEmail();
    }
  }

  emailErrorOnSaveORCIDEmail() {
    this.email = this.email ? this.email.trim() : '';
    const validateEmailRes = validateEmail(this.email);
    if (!this.email.trim() || !validateEmailRes.valid) {
      this.isErrorEmail = true;
      this.errorMsgEmail = !this.email.trim() ? this.emailCannotBeEmptyMessage : validateEmailRes.description || this.invalidEmailFormatMessage;
      this.errorMsgEmail = this.errorMsgEmail === 'Invalid email' ? this.invalidEmailFormatMessage : this.errorMsgEmail; 
      document.getElementById("inputEmail").focus();
    }
  }

  updateSibUserOnSaveORCIDEmail(){
    let body = {
      userId: this.userId,
      optOut: this.optout,
      address: undefined,
      hasAcceptedTerms: this.isTermsChecked
    };

    if (!this.countryExists) { 
      body.address = { country: this.country.name , countryCode: this.getCountryCodeFromCountry(this.country.name)} 
    }
    if (this.clientId) {
      body['clientId'] = this.clientId;
    }
    this.orcidShibbolethService.updateShibbolethUser(body)
      .subscribe(
        (response) => {
          (async () => {
            await this.setResponse(response);
          })();
        },
        (err: any) => {
          this.setErrorResponse(err);
        }
      );
  }

  getTermsChecked() {
    console.log('getTermsChecked', this.isTermsCheckedPrestine)
    return this.isTermsCheckedPrestine ? false : true;
  }

  updateTnFUserOnSaveORCIDEmail(){
    const body = this.createBody('tnf');
    console.log('body tnf', body);

    this.orcidShibbolethService.updateTnfUser(body)
      .subscribe(
        (response) => {
          (async () => {
            await this.setResponse(response);
          })();
        },
        (err: any) => {
          this.setErrorResponse(err);
        }
      );
  }

  createBody(type) {
    let body = {
      optOut: this.optout,
      hasAcceptedTerms: this.isTermsChecked,
      address: undefined,
      consentGiven: this.isTermsChecked
    };
    if(type === 'tnf'){
      body['userId'] = this.userId;
    }
    if(this.countryExists || this.country?.name) {
      body.address = { country: this.country.name , countryCode: this.getCountryCodeFromCountry(this.country.name)}
    }
    if (this.clientId) {
      body['clientId'] = this.clientId;
    }
    if(this.firstName) {
      body['firstName'] = this.firstName;
    }
    if(this.lastName) {
      body['lastName'] = this.lastName;
    }
    if(this.firstName && this.lastName) {
      body['displayName'] = this.firstName + " " + this.lastName;
    }
    if(this.email) {
      body['email'] = this.email;
    }
    console.log('body =========>', body);
    return body;
  }

  updateORCIDUserOnSaveORCIDEmail(){
    this.loading = true;
    const body = this.createBody('orcid');

    this.orcidShibbolethService.updateOrcidUser(body)
      .subscribe(
        (response) => {
          (async () => {
            await this.handleOrcidResponse(response)
          })();
        },
        (err: any) => {
          this.loading = false;
          this.setErrorResponse(err);
        }
      );
  }

  /**
   * Sets The Error Variables Based On the Error Response.
   * Checks whether password was incorrect or Email
   * @param {any} errorMetadata Error Response Sent Back By The API
   */
  private setErrorStateVariables(errorMetadata: any) {
    console.log("inside setErrorStateVariables orcid", errorMetadata);
    if(errorMetadata?.message?.key=="MALICIOUS_ACCOUNT"){
      this.postLoginFailureKey="MALICIOUS_ACCOUNT";
    }else{
      this.isErrorEmail = true;
      this.errorMessage = errorMetadata.message;
      document.getElementById("inputEmail").focus();
    }
  }

  private async redirectToNextPage() {
     await this.appState.loadUserProfileComponent(true);
     await this._route.navigate(['/accounts'], {replaceUrl: true});
  }

  openDialog() {
    const dialogRef = this.dialog.open(OrcidDialogBoxComponent, { width: "525px", disableClose: true, panelClass: 'orcid-dialog-section' });

    dialogRef.afterClosed().subscribe((isConfirmed: boolean) => {
      (async ()=>{
        if (isConfirmed) {
          await this._route.navigate(['/sign-up'], { queryParams: { authorize: this.authorize, client_id: this.clientId, state: this.state } });
        } else {
          this.skipWechatLogin = true;
          this.saveOrcidEmail();
        }
      })();
    })
  }

  validateCountry() {
    this.isIncorrectCountry = false;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.validateCountryBlur();

    }, TIMEOUT_INTERVAL);
  }

  focusCountry() {
    this.isIncorrectCountry = false;
  }

  validateCountryBlur() {
    if (this.country?.name?.length < 2) {
      this.errorMsgCountry = "Country/Region required";
      this.isIncorrectCountry = true;
      document.getElementById("country").focus();
    }
  }

  fetchCountries() {
    console.log('fetchCountries called========>')
    this.orcidShibbolethService.fetchCountries().then(response => {
      this.geoLocations = response;
      console.log('response', response)
      this.allCountries = sortBy(response.countries, 'name');
      const countries = this.allCountries;
      const country = countries.find((e) => { return this.getCountry(e)});
      console.log('country', country);
      if(country && Object.keys(country).length) {
        this.country = country;
      }
      else {
        this.country = {};
      }
      console.log('final country', this.country);
    })
    .catch((err)=>{console.log(err)})
  }

  getCountry(e) {
    if(this.country?.countryCode) {
      return e?.countryCode.toLowerCase() === this.country?.countryCode.toLowerCase();
    }
    else if(this.country?.name) {
      return e?.name.toLowerCase() === this.country?.name.toLowerCase();
    }
  }

  onCountryChange(country) {
    this.country.name = country.name;
    this.country = country;
    console.log('onCountryChange', this.country);
  }

  async setResponse(response) {
    let user = response;
    //console.log(`Is auth flow --  ${this.authorize} :: has user accepted terms --${user.hasAcceptedTerms} :: user id --${user._id}`);
    // User already accepted the terms then log user in, also it shouldnt be a tnflogin since Mfa is already done
    if (user.mFAEnabled && !user.mFAAuthenticated && !this.isTnfLogin) {
      await this.routeMultiFactorAuth(user);
    } else if (user.hasAcceptedTerms) {
      if (this.authorize || this.clientId) {
        const authorizeParams = this.appState.get('authorizeParams');
        const authorizeQueryParams = Object.keys(authorizeParams).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(authorizeParams[k])}`).join('&');
        this.setHref(this.authorizeAction + "?" + authorizeQueryParams);
      } else {
        this.appState.user$.next(user);
        this.appState.set('user', user);
        await this.redirectToNextPage();
      }
      // If not redirect to accept terms page
    } else {
      await this.routeAcceptTerms(user);
    }
  }

  async routeMultiFactorAuth(user) {
    if (this.authorize) {
      await this._route.navigate(['/multi-factor-auth'], { queryParams: { authorize: this.authorize, client_id: this.clientId, userId: user._id, state: this.state } });
    } else if (this.clientId) {
      await this._route.navigate(['/multi-factor-auth'], { queryParams: { userId: user._id, client_id: this.clientId, state: this.state } });
    } else {
      await this._route.navigate(['/multi-factor-auth'], { queryParams: { userId: user._id } });
    }
  }

  async routeAcceptTerms(user){
    if (this.authorize) {
      await this._route.navigate(['/accept-terms'], { queryParams: { authorize: this.authorize, client_id: this.clientId, userId: user._id, state: this.state } });
    } else if (this.clientId) {
      await this._route.navigate(['/accept-terms'], { queryParams: { userId: user._id, client_id: this.clientId, state: this.state } });
    } else {
      await this._route.navigate(['/accept-terms'], { queryParams: { userId: user._id } });
    }
  }

  setErrorResponse(err) {
    this.setErrorStateVariables(err.metadata);
  }

  ngOnDestroy(){
    this.usersDataSubscription?.unsubscribe();
  }

  setHref(url: string) {
    this.document.location.href = url;
  }

  async handleOrcidResponse(userData: any) {
    if (userData.needMfaValidation || !userData.isValidated) {
      this.authServices.sendMFACode(userData.id || userData._id).subscribe(
        () => {
          this.loading = false;
          if (this.authorize) {
            this._route.navigate(['/multi-factor-auth'], { queryParams: { authorize: this.authorize, client_id: this.clientId, userId: userData._id } });
          } else {
            this._route.navigate(['/multi-factor-auth'], { queryParams: { client_id: this.clientId, userId: userData._id } });
          }
        },
        err => {
          this.loading = false;
          this.setErrorResponse(err);
        }
      )
    } else {
      this.loading = false;
      return this.setResponse(userData);
    }
  }

  getCountryCodeFromCountry(countryName: string) {
    const countryData = this.geoLocations.countries.find(data => data.name === countryName);
    let countryCode = ''
    if (countryData) {
      countryCode = countryData.countryCode
    }
    return countryCode
  }  //
  getRequiredDetails() {
    this.showUserDetails = !this.appState.get('queryParams')['email']
    const fName = this.appState.get('queryParams')['firstName']
    const lName = this.appState.get('queryParams')['lastName']
    const country = this.appState.get('queryParams')['country']
    const email = this.appState.get('queryParams')['email']
    if(!fName || !lName || !country) {
      this.showUserDetails = true
      if(this.appState.get('queryParams')['isOrcid'] && email) {
        return
      }
      this.title = this.appState.get('queryParams')['piiValidatedAt'] ? this.title : this.addtionalDetails 
      return
    }
    this.title = this.addtionalDetails;
    this.isVerifyDetailsPage = false;
  }
}
